<shd-expandable-card (click)="toggleDetails()" class="expandable-card" [toggleButtonRight]="true">
    <ng-template shdExpandableCardHeader>
        {{ 'SHD_PROJECT_DETAILS' | translate }}
    </ng-template>
    <ng-template shdExpandableCardContent>
        <div class="project-details">
            <table class="details-table">
                <tbody>
                    <tr>
                        <th>
                            <h4 class="project-name">
                                {{ project.name }}
                            </h4>
                        </th>
                        <td>
                            <strong>{{ 'PROJECT_NUMBER' | translate }}:</strong>
                            {{ project.number }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <div>
                                {{ 'PROJECT_ABBREVIATION' | translate }}: @if(project.abbreviation) {({{ project.abbreviation }})}
                            </div>
                        </th>
                        <td>
                            <strong>{{ 'PROJECT_CUSTOMERNUMBER' | translate }}:</strong>
                            {{ project.customerNumber }}
                        </td>
                    </tr>
                    <tr>
                        <th>{{ 'PROJECT_CUSTOMERNAME' | translate }}: {{ project.customerName }}</th>
                        <td>
                            <strong>{{ 'PROJECT_ORDERDATE' | translate }}:</strong>
                            {{ project.orderDate | date : 'dd.MM.yyyy' }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <shd-contextmenu [overflowHidden]="true">
                <button class="btn btn-contextmenu" (click)="duplicateProject()">
                    <fa-icon class="me-2" [icon]="['fal', 'copy']"></fa-icon>
                    {{ 'BTN_COPY' | translate }}
                </button>
                <button class="btn btn-contextmenu" (click)="editProject()">
                    <fa-icon class="me-2" [icon]="['fal', 'pencil']"></fa-icon>
                    {{ 'BTN_EDIT' | translate }}
                </button>
                <button class="btn btn-contextmenu" (click)="confirmDeleteModal.show()">
                    <fa-icon class="me-2" [icon]="['fal', 'trash-alt']"></fa-icon>
                    {{ 'BTN_DELETE' | translate }}
                </button>
            </shd-contextmenu>
        </div>
    </ng-template>
</shd-expandable-card>

<shd-confirm-modal
    #confirmDeleteModal
    [title]="'BTN_DELETE' | translate"
    [question]="'PROJECT_LABEL_DELETECONFIRM' | translate"
    (confirmed)="deleteProject()"></shd-confirm-modal>
