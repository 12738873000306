import { lastValueFrom } from 'rxjs';
import { Transition, TransitionService } from '@uirouter/core';

import { loginState } from '@obo-account/account.states';
import { AccountService } from '@obo-account/account/account.service';
import { dashboardState } from '@obo-dashboard/states/dashboard.state';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { RegionService } from '@obo-main/services/region/region.service';
import { hasConstructModuleCondition, hasDownloadCondition } from '@obo-portal/models/portal.models';

import { adminOverviewState } from '@obo-admin/admin.states';
import { downloadsState } from '@obo-dashboard/states/download.state';
import { catchError, map } from 'rxjs/operators';
import { Constants } from '../../../constants';

/**
 * redirects logged in users from login page
 * @param transitionService
 */
export function redirectHook(transitionService: TransitionService) {
    const criteria = {
        to: (state: any) => state.name === loginState.name
    };

    const action = (transition: Transition) => {
        const authService = transition.injector().get(AuthService) as AuthService;
        const accountService = transition.injector().get(AccountService) as AccountService;
        const regionService = transition.injector().get(RegionService) as RegionService;
        const $state = transition.router.stateService;
        let targetState: string;
        return lastValueFrom(
            accountService.getAccount().pipe(
                catchError(async () => accountService.getEmptyUser()),
                map((user) => {
                    if (!authService.isTokenExpired() && user.regionId) {
                        if (authService.isAdmin() && !authService.isInRole([Constants.Role.User])) {
                            targetState = adminOverviewState.name;
                        } else {
                            const activeUserRegion = regionService.portalRegions.find((r) => r.id === user.regionId);

                            const hasDashboardState = activeUserRegion?.features.some((f) => hasConstructModuleCondition(f.id));
                            const hasDownloadState = activeUserRegion?.features.some((f) => hasDownloadCondition(f.id));

                            if (hasDashboardState) targetState = dashboardState.name as string;
                            else if (hasDownloadState) targetState = downloadsState.name as string;
                            else return true;
                        }
                        return $state.target(targetState);
                    }
                    return true;
                })
            )
        );
    };
    // add the hook to transitionsservice
    transitionService.onBefore(criteria, action, { priority: 1 });
}
