import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InAppNavigationService } from '@obo-main/services/inAppNavigation/inAppNavigation.service';
import { ConfirmModalComponent } from '@obo-common/shared/components/confirmModal/confirmModal.component';
import { TourEvent, TourService } from '@obo-main/services/tour/tour.service';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { LocationStrategy } from '@angular/common';
import { GuidedTourService } from '@obo-libraries/ngx-guided-tour/guided-tour.service';
import { Subject, takeUntil } from 'rxjs';
import { GridNavigationService } from '@obo-main/services/gridNavigation/gridNavigation.service';
import { StateService } from '@uirouter/core';
import { Constants } from 'app/constants';

@Component({
    selector: 'app-tour',
    templateUrl: './tour.component.html',
    styleUrls: ['./tour.component.scss']
})
export class TourComponent implements OnInit, OnDestroy {
    public showTourToggle: boolean = false;
    public inAppNavActive: boolean = false;
    public isGridLayout: boolean = false;

    private onDestroy = new Subject();

    @ViewChild('confirmModalRef')
    confirmModalRef: ConfirmModalComponent;

    constructor(
        private tourService: TourService,
        private inAppNavigationService: InAppNavigationService,
        private appSettings: AppSettings,
        private locationStrategy: LocationStrategy,
        private guidedTourService: GuidedTourService,
        private gridNavigationService: GridNavigationService,
        private stateService: StateService
    ) {}

    public ngOnInit(): void {
        if (this.appSettings.getItem('settings.enable_tours')) {
            this.tourService.toggleService(true);
            this.tourService.tourChange.subscribe((event: TourEvent) => {
                const isGridLayout = Constants.GRID_LAYOUT_STATES.some((state) => this.stateService.includes(state));
                if (isGridLayout) {
                    this.gridNavigationService.isActive.pipe(takeUntil(this.onDestroy)).subscribe((isActive) => {
                        this.isGridLayout = isActive;
                    });
                }
                if (!event || (event.enabled && event.tour)) {
                    this.inAppNavigationService.isActive
                        .pipe(takeUntil(this.onDestroy))
                        .subscribe((active) => (this.inAppNavActive = active));
                    this.showTourToggle = false;
                } else if (event.enabled && !event.tour) {
                    this.showTourToggle = true;
                }
            });
        }

        this.locationStrategy.onPopState(() => {
            setTimeout(() => {
                this.guidedTourService.resetTour();
            });
        });
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    public toggleRestartModal(): void {
        this.confirmModalRef.show();
    }
    public restartTour(): void {
        this.tourService.restartTour();
    }
}
