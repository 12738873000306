import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { createPatchObject } from '@obo-main/utils/staticHelperFunctions';
import { Building, Project } from '@obo-dashboard/models/projectManagement.models';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatAll, map, mergeMap, toArray } from 'rxjs/operators';

@Injectable()
export class ProjectService {
    constructor(@Inject('APIPREFIX') private apiPrefix: string, private http: HttpClient) {}

    /**
     * Retrieves the whole projectList from Api
     */
    public getProjectList(): Observable<Project[]> {
        return this.http
            .get<Project[]>(`${this.apiPrefix}Projects`)
            .pipe(concatAll(), toArray())
            .pipe(
                map((projects) => {
                    projects.forEach((project) => this.transformOrderDate(project));
                    return projects;
                })
            );
    }

    public getEmptyProject(): Project {
        const project = new Project();
        this.transformOrderDate(project);
        return project;
    }

    /**
     * retrieves a specific project from Api or creates a local one if projectId is -1
     * @param projectId
     */
    public getProject(projectId: number): Observable<Project> {
        if (projectId === -1) {
            return of(this.getEmptyProject());
        } else {
            return this.http.get<Project>(`${this.apiPrefix}Projects/${projectId}`).pipe(
                map((project) => {
                    this.transformOrderDate(project);
                    return project;
                })
            );
        }
    }

    /**
     * retrieves the buildingList from Api or am empty list of projectId is -1
     * @param projectId
     */
    public getBuildingsByProject(projectId: number): Observable<Building[]> {
        if (projectId === -1) {
            return of([]);
        } else {
            return this.http.get<Building[]>(`${this.apiPrefix}Projects/${projectId}/Buildings`);
        }
    }

    /**
     * Use this function to save a project. Depending on the existance of the id property, the service will use post or put
     * @param project
     */
    public createOrUpdateProject(project: Project) {
        if (project.id > 0) {
            return this.updateProject(project);
        } else {
            return this.createProject(project);
        }
    }
    /**
     * transform orderDate of project
     * @param project
     */
    private transformOrderDate(project: Project): void {
        project.orderDate = project.orderDate ? project.orderDate.split('T')[0] : null;
    }

    /**
     * Updates an existing Project
     * @param project
     */
    private updateProject(project: Project): Observable<Project> {
        return this.http.put<Project>(`${this.apiPrefix}Projects/${project.id}`, project);
    }

    /**
     * Updates an existing Project's Name
     * @param project
     * @param projectName
     */
    public updateProjectName(project: Project): Observable<Project> {
        const patchObject = [createPatchObject('name', project.name)];
        return this.http.patch(`${this.apiPrefix}Projects/${project.id}`, patchObject).pipe(map(() => project));
    }

    /**
     * Creates a new Project
     * @param project
     */
    private createProject(project: Project): Observable<Project> {
        return this.http.post<Project>(`${this.apiPrefix}Projects`, project).pipe(
            map((result) => {
                result.orderDate = result.orderDate ? result.orderDate.split('T')[0] : null;
                return result;
            }),
            catchError((err) => {
                return throwError(() => new Error(err));
            })
        );
    }

    /**
     * Delete a Project
     * @param project
     */
    public deleteProject(project: Project): Observable<any> {
        return this.http.delete(`${this.apiPrefix}Projects/${project.id}`);
    }

    /**
     * duplicates the given building of the provided projectId and returns the newly created building
     * @param project project to duplicate
     */
    public duplicateProject(project: Project): Observable<Project> {
        return this.http.request<Project>('copy', `${this.apiPrefix}Projects/${project.id}`).pipe(
            mergeMap((project) => {
                return this.updateProjectsUsageDateTimestamp(project.id).pipe(map(() => project));
            })
        );
    }

    public updateProjectsUsageDateTimestamp(projectId: number): Observable<any> {
        const patchObject = [createPatchObject('usageDate', new Date().toISOString())];
        return this.http.patch(`${this.apiPrefix}Projects/${projectId}`, patchObject);
    }
}
