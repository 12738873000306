<div class="planning-card" [ngClass]="planning.isPlanned ? '' : 'unplanned'">
    <div
        class="planning-card-body"
        (click)="forwardIfAllowed({ id: planning.moduleId, name: planning.name })"
        [attr.data-track]="'enterConfigurator_' + planning.name">
        <div
            class="planning-card-image card-background-image"
            [ngClass]="planning.name"
            [ngStyle]="{ 'background-image': linearGradient + 'url(/assets/img/dashboard/' + planning.name + '.jpg)' }">
            @if (planning.isPlanned) {
            <button class="planning-card-controls-btn">
                {{ 'SHARED_GO_TO_OVERVIEW' | translate }}
            </button>
            } @else {
            <button class="planning-card-controls-btn">
                {{ 'SHARED_START_CONFIGURATOR' | translate }}
            </button>
            }
        </div>
    </div>
    <div class="planning-card-footer">
        <h3 class="m-0">
            {{ 'BUILDING_MODULES_' + planning.name | uppercase | translate }}
        </h3>
        <div class="icons-container">
            @if (planning.isPlanned) { @if (moduleAllowsImportExport()) {
            <div class="btn-icon-wrapper">
                <button class="planning-card-footer-btn" (click)="exportPlanning(planning.name, planning.id)">
                    <fa-icon size="xl" [icon]="['fal', 'file-export']"></fa-icon>
                </button>
            </div>
            }
            <div class="btn-icon-wrapper">
                <button class="planning-card-footer-btn" (click)="planningConfirmDeleteDialog.show()">
                    <fa-icon size="xl" [icon]="['fal', 'trash-alt']"></fa-icon>
                </button>
            </div>
            } @else {
            <div class="btn-icon-wrapper info">
                <button (click)="openDetails.emit(planning)" class="planning-card-footer-btn info">
                    <fa-icon size="xl" [icon]="['fal', 'info-circle']"></fa-icon>
                </button>
            </div>
            }
        </div>
    </div>
</div>

<shd-confirm-modal
    #planningConfirmDeleteDialog
    [title]="'MAIN_DELETING_PLANNING_TITLE' | translate"
    [question]="'MAIN_DELETING_PLANNING_QUESTION' | translate"
    (confirmed)="deletePlanning(planning.id)"></shd-confirm-modal>
