import { Directive, ElementRef, Input, OnChanges, OnDestroy, Renderer2, SimpleChanges } from '@angular/core';
import { ImageService } from '@obo-main/services/image/image.service';

@Directive({
    selector: 'img[shdApiImageSrc]',
    exportAs: 'apiImageDirective'
})
export class ApiImageSrcDirective implements OnChanges, OnDestroy {
    @Input()
    public shdApiImageSrc: string;
    @Input()
    public apiImagePlaceholder: string;

    constructor(private elementRef: ElementRef, private imageService: ImageService, private renderer: Renderer2) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.shdApiImageSrc || changes.apiImagePlaceholder) {
            this.reloadImage();
        }
    }

    public ngOnDestroy(): void {
        this.imageService.revokeObjectUrl(this.elementRef.nativeElement.src);
    }

    public reloadImage(): void {
        if (!this.shdApiImageSrc) {
            this.setPlaceholer();
            return;
        }
        this.imageService.loadApiImage(this.shdApiImageSrc).subscribe({
            next: (img) => {
                this.imageService.revokeObjectUrl(this.elementRef.nativeElement.src);
                this.renderer.setAttribute(this.elementRef.nativeElement, 'src', img);
            },
            error: () => this.setPlaceholer()
        });
    }

    private setPlaceholer(): void {
        this.renderer.setAttribute(this.elementRef.nativeElement, 'src', this.apiImagePlaceholder);
    }
}
