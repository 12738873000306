import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ModuleService } from '@obo-main/services/modules/module.service';
import { Planning } from '@obo-main/services/plannings/plannings.models';
import { ElementNotFoundError, PlanningNotAllowedError } from '@obo-main/utils/errors/errors';
import { createPatchObject } from '@obo-main/utils/staticHelperFunctions';
import { Observable, of, throwError } from 'rxjs';
import { concatAll, filter, map, mergeMap, toArray } from 'rxjs/operators';
import { Utils } from '@obo-main/utils/utils.service';

@Injectable()
export class PlanningService {
    constructor(
        @Inject('APIPREFIX') private apiPrefix: string,
        private http: HttpClient,
        private moduleService: ModuleService,
        private utils: Utils
    ) {}

    /**
     * returns the planningId if it exists or throws an error if there is no planning for the given id
     * @param projectId id of the project to check
     * @param buildingId id of the building to check
     * @param moduleId id of the module to check for planning existance
     */
    public getExistingPlanning(projectId: number, buildingId: number, moduleId: number): Observable<Planning> {
        return this.http.get<Planning[]>(this.planningEndpointUrl(projectId, buildingId)).pipe(
            mergeMap((plannings) => {
                const existingPlanning = plannings.find((p) => p.moduleId === moduleId);
                if (existingPlanning == null) {
                    return throwError(() => new PlanningNotAllowedError('Planing not allowed for selected project region'));
                }
                const isPlanned = existingPlanning!.isPlanned;
                if (!isPlanned) {
                    return throwError(() => new ElementNotFoundError(`No Planning found for moduleId: ${moduleId}`));
                }
                return of(existingPlanning!);
            })
        );
    }

    /**
     * creates a new Planning on API for the specified Project/Building
     * @param projectId
     * @param buildingId
     */
    public createPlanning(projectId: number, buildingId: number, moduleId: number): Observable<Planning> {
        return this.http.post(this.planningEndpointUrl(projectId, buildingId), {
            moduleId: moduleId,
            usageDate: new Date().toISOString()
        }) as Observable<Planning>;
    }

    /**
     * returns the complete PlanningList
     * @param projectId
     * @param buildingId
     */
    public getPlannings(projectId: number, buildingId: number): Observable<Planning[]> {
        return this.http.get<Planning[]>(`${this.planningEndpointUrl(projectId, buildingId)}`).pipe(
            concatAll(),
            filter((s) => {
                try {
                    const module = this.moduleService.getModuleById(s.moduleId);
                    return module.hasPlanning;
                } catch {
                    return false;
                }
            }),
            toArray()
        );
    }

    /**
     * deletes the Planning with the specified Ids
     * @param projectId projectid of the building the planning belongs to
     * @param buildingId id of the building the planning belongs to
     * @param planningId PlanningId to remove
     */
    public deletePlanning(projectId: number, buildingId: number, planningId: number): Observable<any> {
        return this.http.delete(`${this.planningEndpointUrl(projectId, buildingId)}/${planningId}`);
    }

    public updatePlanningsUsageDateTimestamp(projectId: number, buildingId: number, planningId: number): Observable<any> {
        const patchObject = [createPatchObject('usageDate', new Date().toISOString())];
        return this.http.patch(`${this.planningEndpointUrl(projectId, buildingId)}/${planningId}`, patchObject);
    }

    /**
     * returns the imageUrl of the module
     * @param planning
     */
    public getPlanningTileUrl(planning: Planning): string {
        let moduleName = this.moduleService.getModuleById(planning.moduleId)!.name;
        return `/assets/img/dashboard/${moduleName}.jpg`;
    }

    /**
     * Builds the state of for the summary Link
     * @param planning
     */
    public getPlanningState(planning: Planning): string {
        let moduleName = this.moduleService.getModuleById(planning.moduleId)!.name;
        return moduleName ?? '';
    }

    /**
     * imports planning json file for specific module
     * @param module
     * @param projectId
     * @param buildingId
     * @param planningJson
     */
    public import(module: string, projectId: number, buildingId: number, planningJson: FormData): Observable<any> {
        return this.http.post<FormData>(this.importEndpointUrl(module, projectId, buildingId), planningJson);
    }

    /**
     * exports planning as json file for specific module
     * @param module
     * @param projectId
     * @param buildingId
     * @param planningId
     */
    public export(
        module: string,
        projectId: number,
        buildingId: number,
        planningId: number
    ): Observable<{ name: string; blob: Blob }> {
        return this.http
            .get(this.exportEndpointUrl(module, projectId, buildingId, planningId), {
                headers: new HttpHeaders().append('accept', 'application/json'),
                observe: 'response',
                responseType: 'arraybuffer'
            })
            .pipe(
                map((response: HttpResponse<ArrayBuffer>) => {
                    return {
                        name: this.utils.parseFileNameFromHeader(response.headers),
                        blob: new Blob([response.body!], {
                            type: 'application/json'
                        })
                    };
                })
            );
    }

    private importEndpointUrl(module: string, projectId: number, buildingId: number): string {
        return `${this.apiPrefix}${module}/Projects/${projectId}/Buildings/${buildingId}/Plannings/Imports/Json`;
    }

    private exportEndpointUrl(module: string, projectId: number, buildingId: number, planningId: number): string {
        return `${this.apiPrefix}${module}/Projects/${projectId}/Buildings/${buildingId}/Plannings/${planningId}/Exports/Json`;
    }

    private planningEndpointUrl(projectId: number, buildingId: number): any {
        return `${this.apiPrefix}Projects/${projectId}/Buildings/${buildingId}/Plannings`;
    }
}
