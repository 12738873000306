import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { FilterPrediction, OboFilter, OboFilterTagGroup, OboFilterTagGroupAccessor } from '@obo-common/filter/models/Filter';
import { EMPTY, Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

/**
 * MultiSelectFilter. Use this class as FormControl on MultiSelectDropdownFilterComponent
 * @constructor constructs a new OboMultiSelectFilter
 * @extends FormArray
 * @implements OboFilter, OboFilterTagGroupAccessor, FilterPrediction
 */
export class OboMultiSelectFilter<T>
    extends UntypedFormArray
    implements OboFilter<T>, OboFilterTagGroupAccessor<T>, FilterPrediction<T>
{
    filterConcatenation: 'and' | 'or' = 'or';
    get isActive(): boolean {
        return !this.disabled && this.controls.some((o) => o.isActive);
    }

    initialValue: any;
    label: string;
    hasPrediction: boolean;
    hasFilterTag: boolean;
    controls: Array<OboMultiSelectFilterOption<T>> = [];

    constructor(
        label: string,
        hasPrediction: boolean,
        options: Array<OboMultiSelectFilterOption<T>>,
        hasFilterTag: boolean = true,
        filterConcatenation: 'and' | 'or' = 'or'
    ) {
        super(options, { updateOn: 'submit' });
        this.controls = options;
        this.label = label;
        this.hasFilterTag = hasFilterTag;
        this.hasPrediction = hasPrediction;
        this.filterConcatenation = filterConcatenation;
    }

    /**
     * Checks if the Item fulfills the filterFunction
     * @param item the item to apply the filterFn on
     */
    checkItem(item: T): boolean {
        if (this.isActive) {
            const activeOptions = this.controls.filter((c) => c.isActive || c.predictionCalcMode);
            return this.filterConcatenation === 'or'
                ? activeOptions.some((o) => o.filterFn(item, o.filterValue))
                : activeOptions.every((o) => o.filterFn(item, o.filterValue));
        } else {
            return true;
        }
    }

    /**
     *
     * @param itemList the productsList at the current time. Since the calculation should be performed after filtering, it should the the currently displaying list
     * @param applyFilterFn the function which will deliver the filtered ProductsList
     */
    calculatePrediction(itemList: T[], applyFilterFn: () => Observable<T[]>): Observable<boolean> {
        if (!this.hasPrediction) {
            return EMPTY;
        }
        return from(this.controls).pipe(
            mergeMap((c) => {
                if (this.isActive && !c.isActive) {
                    c.predictionCalcMode = true;
                    return applyFilterFn().pipe(
                        mergeMap((filteredItemList) => {
                            const count = filteredItemList.filter((item) => c.filterFn(item, c.filterValue)).length;
                            c.prediction = `(+${count})`;
                            c.predictionCalcMode = false;
                            return EMPTY;
                        })
                    );
                } else {
                    const count = itemList.filter((item) => c.filterFn(item, c.filterValue)).length;
                    c.prediction = `(${count})`;
                    return EMPTY;
                }
            })
        );
    }

    /**
     * returns the Filtertags
     */
    getTags(): OboFilterTagGroup {
        return {
            label: this.label,
            tags: this.controls
                .filter((c) => c.isActive)
                .map((control) => ({
                    label: control.label,
                    reset: control.reset.bind(control)
                }))
        };
    }
}

/**
 * A FilterOption for the MultiSelectFilter.
 * @constructor creates a new MultiSelectOption
 * @extends FormControl
 */
export class OboMultiSelectFilterOption<T> extends UntypedFormControl {
    // @ts-ignore
    get value(): boolean {
        return this.isActive;
    }

    set value(val: boolean) {
        this.isActive = val;
    }

    predictionCalcMode: boolean = false; // this will override the isActive Property.
    isActive: boolean;
    label: string;
    image?: string;
    prediction?: string;
    initialValue?: boolean;
    filterFn: (item: T, filterValue: any) => boolean;
    filterValue: any;

    constructor(
        label: string,
        filterValue: any,
        filterFn: (item: T, filterValue: any) => boolean,
        initialValue?: boolean,
        image?: string
    ) {
        super(initialValue, { updateOn: 'submit' });
        this.label = label;
        this.filterValue = filterValue;
        this.initialValue = initialValue;
        this.filterFn = filterFn;
        this.image = image;
    }

    reset(value?: any, opts?: { onlySelf?: boolean; emitEvent?: boolean }): void {
        super.reset(value || this.initialValue, opts);
    }
}
