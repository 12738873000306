import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GridNavigationService {
    private activeSubject = new BehaviorSubject<boolean>(false);
    public isActive = this.activeSubject.asObservable();

    public setActive(isActive: boolean): void {
        this.activeSubject.next(isActive);
    }
}
