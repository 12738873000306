<div class="sidebar" [ngClass]="isMobile ? 'sidebar-bottom' : 'sidebar-' + position" [class.opened]="opened">
    <div class="sidebar-header">
        <button type="button" class="sidebar-close-btn" (click)="toggle()">
            <fa-icon [icon]="['fal', 'times']" size="2x"></fa-icon>
        </button>
    </div>
    @if (contentTpl) {
    <div class="sidebar-content">
        <ng-container [ngTemplateOutlet]="contentTpl.templateRef"></ng-container>
    </div>
    }
</div>
@if (showBackdrop) {
<div class="sidebar-backdrop" [class.show]="opened" (click)="toggle()"></div>
}
