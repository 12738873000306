import { lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '@obo-account/account/account.service';
import { RegionPipe } from '@obo-common/shared/pipes/region.pipe';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { ContactComponent } from '@obo-static/contact/contact.component';
import { ContactFormComponent } from '@obo-static/contact/contactForm/contactForm.component';
import { DisplayContentComponent } from '@obo-static/displayContent/displayContent.component';
import { DisplayErrorComponent } from '@obo-static/displayError/displayError.component';
import { ElbridgeFormComponent } from '@obo-static/elbridgeForm/elbridgeForm.component';
import { ContactFormUser } from '@obo-static/models/static.models';
import { Transition, UIView } from '@uirouter/angular';
import { Constants } from 'app/constants';
import { map, mergeMap } from 'rxjs/operators';
import { decode } from 'url-safe-base64';
import { NoProductsFoundComponent } from '@obo-static/noProductsFound/noProductsFound.component';

export const abstractStaticState = {
    parent: 'app',
    url: '/static',
    name: 'static',
    data: {
        module: 'static'
    },
    component: UIView
};

export const contactState = {
    parent: 'static',
    url: '/contact',
    name: 'contact',
    component: ContactComponent,
    redirectTo: 'contactForm'
};

export const contactFormState = {
    parent: 'contact',
    url: '/contactform',
    name: 'contactForm',
    data: {
        additionalModules: ['portal'],
        scrollTo: '#static-contact'
    },
    views: {
        form: {
            component: ContactFormComponent
        }
    },
    resolve: [
        {
            token: 'user',
            deps: [AuthService, AccountService, RegionPipe, TranslateService],
            resolveFn: getUser
        }
    ]
};

export const imprintState = {
    parent: 'static',
    url: '/imprint',
    name: 'imprint',
    data: {
        translationKey: 'TEMPLATES_IMPRINT',
        metaTitleKey: 'IMPRINT_META_TITLE',
        additionalModules: ['legal'],
        scrollTo: '#static-displayContent'
    },
    component: DisplayContentComponent
};

export const termsOfServiceState = {
    parent: 'static',
    url: '/termsofservice',
    name: 'termsOfService',
    data: {
        translationKey: 'TEMPLATES_TERMSOFSERVICE',
        metaTitleKey: 'TERMSOFSERVICE_META_TITLE',
        additionalModules: ['legal'],
        scrollTo: '#static-displayContent'
    },
    component: DisplayContentComponent,
    resolve: [
        {
            token: 'showGoBackButton',
            deps: [Transition],
            resolveFn: showGoBackButton
        }
    ]
};

export const privacyState = {
    parent: 'static',
    url: '/privacy',
    name: 'privacy',
    data: {
        translationKey: 'TEMPLATES_PRIVACYAGREEMENT',
        metaTitleKey: 'PRIVACY_META_TITLE',
        additionalModules: ['legal'],
        scrollTo: '#static-displayContent'
    },
    component: DisplayContentComponent,
    resolve: [
        {
            token: 'showGoBackButton',
            deps: [Transition],
            resolveFn: showGoBackButton
        }
    ]
};

//UFS Specific States

export const ufsDeviceUnitGroupDatatechnologyAllocationState = {
    parent: 'static',
    url: '/underfloor/du/networking/allocation',
    name: 'ufsDeviceUnitGroupDatatechnologyAllocation',
    data: {
        translationKey: 'TEMPLATES_UFS_DEVICEUNITGROUP_DATATECHNOLOGY_ALLOCATION',
        metaTitleKey: 'DEVICEUNITGROUP_DATATECHNOLOGY_ALLOCATION_META_TITLE'
    },
    component: DisplayContentComponent
};

export const errorState = {
    name: 'errorState',
    parent: 'static',
    url: '/error',
    component: DisplayErrorComponent,
    params: {
        errorName: undefined,
        error: undefined
    },
    resolve: [
        {
            token: 'errorName',
            deps: [Transition],
            resolveFn: getErrorName
        },
        {
            token: 'error',
            deps: [Transition],
            resolveFn: getError
        }
    ]
};

export const elbridgeFormState = {
    name: 'elbridgeForm',
    parent: 'static',
    url: '/elbridgeForm?elbridgeData',
    component: ElbridgeFormComponent,
    params: {
        elbridgeData: null
    },
    resolve: [
        {
            token: 'elbridgeData',
            deps: [Transition],
            resolveFn: getElbridgeData
        }
    ]
};

export const noProductsFoundState = {
    name: 'noProductsFoundState',
    parent: 'static',
    url: '/noProductsFound',
    component: NoProductsFoundComponent
};

export const STATIC_STATES: any = [
    abstractStaticState,
    contactFormState,
    contactState,
    termsOfServiceState,
    imprintState,
    privacyState,
    ufsDeviceUnitGroupDatatechnologyAllocationState,
    errorState,
    elbridgeFormState,
    noProductsFoundState
];

export function showGoBackButton(transition: Transition): boolean {
    return transition.from().name!.length > 0;
}

export function getElbridgeData(transition: Transition): any {
    const encodedElbridgeData = atob(decode(transition.params().elbridgeData));
    return JSON.parse(encodedElbridgeData);
}

export function getErrorName(transition: Transition): string {
    return transition.params().errorName;
}

export function getError(transition: Transition): any {
    return transition.params().error;
}

export function getUser(
    authService: AuthService,
    accountService: AccountService,
    regionPipe: RegionPipe,
    translateService: TranslateService
): Promise<ContactFormUser> {
    if (authService.isInRole([Constants.Role.User])) {
        return lastValueFrom(
            accountService.getAccount(true).pipe(
                mergeMap((user) =>
                    regionPipe.transform(user.regionId).pipe(
                        map((region) =>
                            Object.assign(new ContactFormUser(), user, {
                                country: translateService.instant(region.displayName || ''),
                                title: user.title ? translateService.instant(user.title || '') : null
                            })
                        )
                    )
                )
            )
        );
    }
    return Promise.resolve(new ContactFormUser());
}
