import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OboSingleSelectFilter } from '@obo-common/filter/models/singleSelect';
import { OboMultiSelectFilter } from '@obo-common/filter/models/multiSelect';

@Injectable()
export class FilterService {
    private singleFilterSubject: Subject<OboSingleSelectFilter<any>> = new Subject<OboSingleSelectFilter<any>>();
    private multiFilterSubject: Subject<OboMultiSelectFilter<any>> = new Subject<OboMultiSelectFilter<any>>();

    public setSingleSelectFilter(filter: OboSingleSelectFilter<any>) {
        this.singleFilterSubject.next(filter);
    }

    public singleSelectFilterChange(): Observable<OboSingleSelectFilter<any>> {
        return this.singleFilterSubject.asObservable();
    }

    public setMultiSelectFilter(filter: OboMultiSelectFilter<any>) {
        this.multiFilterSubject.next(filter);
    }

    public multiSelectFilterChange(): Observable<OboMultiSelectFilter<any>> {
        return this.multiFilterSubject.asObservable();
    }
}
