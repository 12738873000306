import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class InAppNavigationService {
    public isActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public saveButtonTemplate: BehaviorSubject<TemplateRef<any>> = new BehaviorSubject<TemplateRef<any>>(undefined);
    public additionalButtonTemplate: BehaviorSubject<TemplateRef<any>> = new BehaviorSubject<TemplateRef<any>>(undefined);
    public onPreviousClick: BehaviorSubject<(() => any) | undefined> = new BehaviorSubject<(() => any) | undefined>(undefined);
    public onNextClick: BehaviorSubject<() => any> = new BehaviorSubject<() => any>(() => {});
    public nextButtonDisabledFn: BehaviorSubject<() => boolean> = new BehaviorSubject<() => boolean>(() => false);
}
