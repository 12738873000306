import { lastValueFrom } from 'rxjs';
import { catchError, defaultIfEmpty, filter, map, mergeMap } from 'rxjs/operators';
import { Transition, TransitionService } from '@uirouter/core';
import { ModuleService } from '@obo-main/services/modules/module.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { TranslateHttpLoader } from '@obo-main/utils/translateLoader/translateHttpCustomLoader.service';
import { from, of } from 'rxjs';

/**
 * Checks if a new translationpart needs to be downloaded for that state
 * @param transitionService
 */
export function loadTranslationHook(transitionService: TransitionService) {
    const translationPartNeededCriteria = {
        entering: (state: any) => state.data && state.data.module
    };

    const action = (transition: Transition) => {
        const moduleService = transition.injector().get(ModuleService) as ModuleService;
        const logger = transition.injector().get(Logger) as Logger;
        const translateLoader = transition.injector().get(TranslateHttpLoader) as TranslateHttpLoader;
        const $state = transition.router.stateService;

        const moduleList = new Array<string>();
        moduleList.push(transition.to().data.module); // push the module of the state
        const additionalmodules = transition.to().data.additionalModules;
        if (additionalmodules) {
            // check if we need some additional modules
            moduleList.push(...additionalmodules); // push additional needed modules
        }

        return lastValueFrom(
            from(moduleList).pipe(
                map((module) => moduleService.getModuleByName(module).id),
                filter((moduleId) => !translateLoader.isAlreadyInStore(moduleId)),
                mergeMap((moduleId) =>
                    translateLoader.addPart(moduleId).pipe(
                        map(() => true),
                        catchError((err) => {
                            logger.error(`Error getting Translation for ModuleId: ${moduleId}`, err);
                            return of(true);
                        })
                    )
                ),
                catchError((err) => {
                    logger.error('Error Getting Translation', err);
                    return of(true);
                }),
                defaultIfEmpty(null)
            )
        );
    };

    // add the hook to transitionsservice
    transitionService.onStart(translationPartNeededCriteria, action, {
        priority: 20
    });
}
