import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: 'input[shdSelectOnFocus]'
})
export class SelectOnFocusDirective {
    constructor(private element: ElementRef) {}

    @HostListener('focus') onFocus() {
        this.element.nativeElement.select();
    }
}
