import { Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SidebarTitleDirective } from '@obo-common/shared/components/sidebar/sidebarTitle.directive';
import { SidebarContentDirective } from '@obo-common/shared/components/sidebar/sidebarContent.directive';
import { Subject, takeUntil } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
    selector: 'shd-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
    @Input() opened: boolean = false;
    @Input() showBackdrop: boolean = true;
    @Input() position: 'left' | 'right' | 'bottom' = 'right';
    @Output()
    openedChange = new EventEmitter<boolean>();

    public isMobile: boolean = false;
    private onDestroy = new Subject();

    @ContentChild(SidebarTitleDirective)
    titleTpl: SidebarTitleDirective;

    @ContentChild(SidebarContentDirective)
    contentTpl: SidebarContentDirective;

    constructor(private breakpointObserver: BreakpointObserver) {}

    public ngOnInit(): void {
        this.breakpointObserver
            .observe(['(max-width: 576px)'])
            .pipe(takeUntil(this.onDestroy))
            .subscribe((state) => {
                this.isMobile = state.matches;
            });
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    public toggle(): void {
        this.opened = !this.opened;
        this.openedChange.emit(this.opened);
    }
}
