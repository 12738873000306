import { Component, ContentChild, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { OboSingleSelectFilter, OboSingleSelectFilterOption } from '@obo-common/filter/models/singleSelect';
import { FilterOptionDirective } from '@obo-common/filter/helpers/filterItemDirective.directive';
import { FilterService } from '@obo-common/filter/services/filter.service';

let selectId: number = 0;
@Component({
    selector: 'shd-single-filter-select',
    templateUrl: './singleFilterSelect.component.html',
    exportAs: 'filterSelect'
})
export class SingleFilterSelectComponent implements OnInit, OnDestroy {
    public onDestroy = new Subject<any>();

    @Input()
    public filter: OboSingleSelectFilter<any>;
    @Input()
    public applyBtnText: string = 'apply';
    @Input()
    public resetBtnText: string = 'reset';
    @Input()
    public withBorder: boolean;
    @Input()
    public showSelectedValue: boolean = false;

    @HostBinding('class.dropdown-filter')
    public dropdownFilterClass: boolean = true;

    @ViewChild(NgbDropdown)
    public dropdown: NgbDropdown;

    @ContentChild(FilterOptionDirective)
    public filterOptionTpl: FilterOptionDirective;

    public id: string = `singleFilterSelect_${selectId++}`;

    constructor(private filterService: FilterService) {}

    public ngOnInit(): void {
        this.filter.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => this.dropdown.close());
    }
    public ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    toggleFilter(): void {
        this.filterService.setSingleSelectFilter(this.filter);
    }
}
