import { lastValueFrom } from 'rxjs';
import { Transition, TransitionService } from '@uirouter/core';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { defer, iif, of } from 'rxjs';
import { catchError, defaultIfEmpty, map } from 'rxjs/operators';
import { loginState } from '@obo-account/account.states';

/**
 * Checks if the User belongs to a one of many roles defined in state data -> roles array
 * This implies that the user is logged in
 * @param transitionService
 */
export function requiredRoleHook(transitionService: TransitionService) {
    const requiresRoleCriteria = {
        entering: (state: any) => state.data && state.data.roles && Array.isArray(state.data.roles) && state.data.roles.length > 0
    };

    const action = (transition: Transition) => {
        const authService = transition.injector().get(AuthService) as AuthService;
        const stateService = transition.router.stateService;
        const logger = transition.injector().get(Logger) as Logger;

        return lastValueFrom(
            iif(
                () => authService.isTokenExpired(), // checks if user is logged in
                defer(() => authService.refreshAccessToken()),
                of(true)
            )
                .pipe(
                    map(() => {
                        if (!authService.isInRole(transition.to().data.roles)) {
                            logger.error(
                                `You are not allowed to enter State: ${
                                    transition.to().name
                                } Reason: You do not belong to one of these roles: ${transition.to().data.roles}`
                            );
                            return stateService.target(loginState.name);
                        } else {
                            return true;
                        }
                    }),
                    catchError((err) => {
                        logger.error('error refreshing accesstoken while checking user role');
                        const redirectState = transition.to().name;
                        const redirectParams = transition.params('entering');
                        return of(
                            stateService.target(loginState.name, {
                                redirectState: redirectState,
                                redirectParams: redirectParams
                            })
                        );
                    })
                )
                .pipe(defaultIfEmpty(null))
        );
    };

    // add the hook to transitionsservice
    transitionService.onBefore(requiresRoleCriteria, action, { priority: 30 });
}
