import { Platform } from '@angular/cdk/platform';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@obo-main/services/language/language.service';
import { SEOService } from '@obo-main/services/seo/seo.service';
import { tagManagerConfig } from '@obo-main/tagManagerConfig';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { map, startWith } from 'rxjs/operators';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { Constants } from 'app/constants';

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit, AfterViewInit {
    public showEnvironmentAlert: boolean;

    public get showProfileCompletionReminder() {
        return !this.uiRouterGlobals.current.data.inConfiguration;
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private document: Document,
        @Inject('ENVIRONMENT') public environment: string,
        logger: Logger,
        private stateService: StateService,
        private translateService: TranslateService,
        private globalizationService: LanguageService,
        private renderer: Renderer2,
        private appSettings: AppSettings,
        private platform: Platform,
        private seo: SEOService,
        private alertService: AlertService,
        private uiRouterGlobals: UIRouterGlobals
    ) {
        logger.debug('Application bootstrap completed');
    }

    public ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            switch (this.environment) {
                case 'production':
                    this.insertTagManagerScript();
                    break;
                case 'staging':
                case 'evaluation':
                    this.showEnvironmentAlert = true;
                    break;
            }

            if (this.appSettings.getItem('settings.enable_hotjar')) {
                this.insertHotjarSnippet();
            }

            this.addBrowserengineClass();
        }

        this.seo.updateDescription(this.translateService.instant('GENERAL_META_DESCRIPTION'));
    }

    public ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.setEnvironmentClass();
            this.translateService.onLangChange
                .pipe(
                    map((evt) => evt.lang),
                    startWith(this.translateService.currentLang)
                )
                .subscribe((lang) => {
                    this.setLanguageAttribute(lang);
                    this.setFontClass(this.globalizationService.findFontForLanguage(lang));
                });
        }

        if (this.showEnvironmentAlert) {
            this.alertService.warning(this.translateService.instant('ROOT_SHOW_ENVIROMENT_ALERT'));
        }
    }

    public get containerClass(): string {
        let containerClass = 'container legacy';
        if (Constants.GRID_LAYOUT_STATES.some((state) => this.stateService.includes(state))) containerClass = 'grid-layout';
        if (this.stateService.includes('admin') || this.stateService.includes('landing') || this.stateService.includes('portal'))
            containerClass = 'legacy';
        if (this.stateService.includes('errorState')) containerClass = 'legacy';
        if (this.stateService.includes('egress')) {
            containerClass = 'container legacy catalog';
        }
        return containerClass;
    }

    private setLanguageAttribute(lang: string): void {
        this.renderer.setAttribute(this.document.body, 'lang', lang);
    }

    private setFontClass(fontClass: string): void {
        Array.from(this.document.body.classList)
            .filter((s) => s.includes('font-'))
            .forEach((c) => this.renderer.removeClass(this.document.body, c));
        this.renderer.addClass(this.document.body, `font-${fontClass}`);
    }

    private insertTagManagerScript(): void {
        const script = this.renderer.createElement('script') as HTMLScriptElement;
        script.text = tagManagerConfig;
        this.renderer.appendChild(this.document.head, script);
    }

    private setEnvironmentClass(): void {
        this.renderer.setAttribute(this.document.body, 'environment', this.environment);
    }

    private insertHotjarSnippet(): void {
        const script = this.renderer.createElement('script') as HTMLScriptElement;
        script.innerHTML = `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:1337495,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;
        this.renderer.appendChild(this.document.head, script);
    }

    private addBrowserengineClass(): void {
        Object.getOwnPropertyNames(this.platform)
            .filter((key) => (this.platform as any)[key] === true)
            .forEach((engine) => {
                this.renderer.setAttribute(this.document.body, `platform-${engine.toLowerCase()}`, '');
            });
    }
}
