import { lastValueFrom } from 'rxjs';
import { LOCALE_ID } from '@angular/core';
import { AdminAbstractComponent } from '@obo-admin/adminAbstract/adminAbstract.component';
import { AdminOverviewComponent } from '@obo-admin/adminOverview/adminOverview.component';
import { CultureManagementComponent } from '@obo-admin/cultureManagement/cultureManagement.component';
import { MotdManagementComponent } from '@obo-admin/motdManagement/motdManagement.component';
import { MotdManagementService } from '@obo-admin/motdManagement/motdManagement.service';
import { PortalManagementComponent } from '@obo-admin/portalManagement/portalManagement.component';
import { CableManagementComponent } from '@obo-admin/productManagement/cableManagement/cableManagement.component';
import { DatabaseLookupComponent } from '@obo-admin/productManagement/databaseLookup/databaseLookup.component';
import { ProductManagementComponent } from '@obo-admin/productManagement/productManagement.component';
import { RegionManagementComponent } from '@obo-admin/regionManagement/regionManagement.component';
import { TranslationManagementComponent } from '@obo-admin/translationManagement/translationManagement.component';
import { UserManagementComponent } from '@obo-admin/userManagement/userManagement.component';
import { LanguageService } from '@obo-main/services/language/language.service';
import { LocaleService } from '@obo-main/services/locale/locale.service';
import { RegionService } from '@obo-main/services/region/region.service';
import { Transition } from '@uirouter/core';
import { Constants } from 'app/constants';
import { AnalyticsManagementComponent } from './analyticsManagement/analyticsManagement.component';

export const adminAbstractState = {
    parent: 'app',
    url: '/admin',
    name: 'admin',
    redirectTo: 'adminOverview',
    data: {
        module: 'admin',
        additionalModules: ['shared'],
        roles: [
            Constants.Role.AccountAdmin,
            Constants.Role.ProductAdmin,
            Constants.Role.ContentAdmin,
            Constants.Role.AnalyticsAdmin
        ]
    },
    component: AdminAbstractComponent,
    onEnter: loadLocale
};

export const adminOverviewState = {
    parent: 'admin',
    url: '/overview',
    name: 'adminOverview',
    component: AdminOverviewComponent
};

export const translationManagementState = {
    parent: 'admin',
    url: '/translation',
    name: 'translationManagement',
    component: TranslationManagementComponent,
    data: {
        additionalModules: ['shared']
    }
};

export const userManagementState = {
    parent: 'admin',
    url: '/users',
    name: 'userManagement',
    component: UserManagementComponent,
    resolve: [
        {
            token: 'regionList',
            deps: [RegionService],
            resolveFn: getRegionList
        }
    ]
};

export const portalManagementState = {
    parent: 'admin',
    url: '/portal',
    name: 'portalManagement',
    component: PortalManagementComponent
};

export const cultureManagementState = {
    parent: 'admin',
    url: '/cultures',
    name: 'cultureManagement',
    component: CultureManagementComponent
};

export const motdManagementState = {
    parent: 'admin',
    url: '/dashboard/motd',
    name: 'motdManagement',
    component: MotdManagementComponent,
    resolve: [
        {
            token: 'motdList',
            deps: [MotdManagementService],
            resolveFn: getMotdListFromService
        },
        {
            token: 'availableLanguages',
            deps: [LanguageService],
            resolveFn: getAvailableLanguagesForGeneralModuleFromService
        }
    ]
};

export const regionManagementState = {
    parent: 'admin',
    url: '/regions',
    name: 'regionManagement',
    component: RegionManagementComponent
};

export const productManagementState = {
    parent: 'admin',
    url: '/products',
    name: 'productManagement',
    redirectTo: 'cableManagement',
    component: ProductManagementComponent
};

export const analyticsManagementState = {
    parent: 'admin',
    url: '/analytics',
    name: 'analyticsManagement',
    component: AnalyticsManagementComponent
};

export const cableManagementState = {
    parent: 'admin',
    url: '/cables',
    name: 'cableManagement',
    component: CableManagementComponent,
    data: {
        scrollTo: '#cableManagement'
    }
};

export const databaseLookupState = {
    parent: 'admin',
    url: '/databaseLookup',
    name: 'databaseLookup',
    component: DatabaseLookupComponent
};

export const ADMIN_STATES: any = [
    adminAbstractState,
    adminOverviewState,
    translationManagementState,
    userManagementState,
    cultureManagementState,
    portalManagementState,
    motdManagementState,
    regionManagementState,
    cableManagementState,
    productManagementState,
    analyticsManagementState,
    databaseLookupState
];

export function getMotdListFromService(motdService: MotdManagementService) {
    return lastValueFrom(motdService.getMotdList());
}

export function getAvailableLanguagesForGeneralModuleFromService(globalizationService: LanguageService) {
    return lastValueFrom(globalizationService.getAvailableLanguagesForModuleById(0));
}

export function getRegionList(regionService: RegionService) {
    return lastValueFrom(regionService.getRegions());
}

export function loadLocale(transition: Transition): Promise<any> {
    const localeId = transition.injector().get(LOCALE_ID);
    const localeService = transition.injector().get(LocaleService);
    return localeService.loadLocale(localeId);
}
