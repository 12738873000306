import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '@obo-account/account/account.service';
import { User } from '@obo-account/models/account.models';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { Region } from '@obo-main/services/region/region.models';
import { SEOService } from '@obo-main/services/seo/seo.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
    @Input()
    public user: User;
    @Input()
    public passwordPattern: string;
    @Input()
    public regionList: Array<Region>;
    constructor(
        private stateService: StateService,
        private seo: SEOService,
        private authService: AuthService,
        private logger: Logger,
        private translateService: TranslateService,
        private accountService: AccountService,
        private spinnerService: SpinnerService,
        private alertSerice: AlertService
    ) {}

    public ngOnInit(): void {
        this.seo.updateTitle(this.translateService.instant('ACCOUNT_META_TITLE'));
    }

    public updateAccount(user: User): void {
        this.spinnerService.startSpinner(this.translateService.instant('LOADING'));
        this.accountService.updateAccount(user).subscribe({
            next: () => {
                this.alertSerice.success(this.translateService.instant('USERPROFILECTRL_UPDATESUCCESS'));
            },
            error: () => {
                this.alertSerice.success(this.translateService.instant('USERPROFILECTRL_UPDATEFAILURE'));
            },
            complete: () => {
                this.spinnerService.stopSpinner();
            }
        });
    }

    public deleteAccount(): void {
        this.spinnerService.startSpinner(this.translateService.instant('LOADING'));
        this.accountService.deleteAccount().subscribe({
            next: () => {
                this.alertSerice.success(this.translateService.instant('USERPROFILECTRL_DELETE_ACCOUNT_SUCCESS'));
                this.authService.logout();
                this.stateService.go('portal');
            },
            error: () => {
                this.alertSerice.success(this.translateService.instant('USERPROFILECTRL_DELETE_ACCOUNT_FAILURE'));
            },
            complete: () => {
                this.spinnerService.stopSpinner();
            }
        });
    }

    public changePassword(user: User): void {
        this.spinnerService.startSpinner(this.translateService.instant('LOADING'));
        this.accountService.changePassword(user).subscribe({
            next: () => {
                this.alertSerice.success(this.translateService.instant('USERPROFILECTRL_CHANGEPASSWORDSUCCESS'));
            },
            error: () => {
                this.alertSerice.success(this.translateService.instant('USERPROFILECTRL_CHANGEPASSWORDFAILURE'));
            },
            complete: () => {
                this.spinnerService.stopSpinner();
            }
        });
    }

    public resetForm(form: NgForm) {
        form.reset();
        form.form.markAsPristine();
        form.form.markAsUntouched();
    }
}
