import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RequestPasswordService } from '@obo-account/requestPassword/requestPassword.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { SEOService } from '@obo-main/services/seo/seo.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'app-request-password',
    templateUrl: './requestPassword.component.html'
})
export class RequestPasswordComponent implements OnInit {
    @Input()
    public requestType: string = '';
    @Input()
    public email: string = '';
    reCaptchaValid: boolean = false;
    constructor(
        private logger: Logger,
        private seo: SEOService,
        private translateService: TranslateService,
        private requestPasswordService: RequestPasswordService,
        private spinnerService: SpinnerService,
        private alertService: AlertService,
        private stateService: StateService
    ) {}

    public ngOnInit(): void {
        this.seo.updateTitle(this.translateService.instant('RESET_PASSWORD_META_TITLE'));
    }

    public request(): void {
        switch (this.requestType) {
            case 'activationEmail':
                this.requestConfirmationMail(this.email);
                break;
            case 'password':
                this.requestPassword(this.email);
                break;
            default:
                this.logger.error(`Invalid requestType`);
        }
    }

    private requestPassword(email: string): void {
        this.spinnerService.startSpinner(this.translateService.instant('LOADING'));
        this.requestPasswordService.requestNewPassword(email).subscribe({
            next: () => {
                this.alertService.success(this.translateService.instant('LOGINCTRL_FORGOTPASSWORDSUCCESS'));
                this.spinnerService.stopSpinner();
                this.stateService.go('login');
            },
            error: () => {
                this.alertService.danger(this.translateService.instant('LOGINCTRL_FORGOTPASSWORDFAILURE'));
                this.spinnerService.stopSpinner();
            }
        });
    }

    private requestConfirmationMail(email: string): void {
        this.spinnerService.startSpinner(this.translateService.instant('LOADING'));
        this.requestPasswordService.requestActivationMail(email).subscribe({
            next: () => {
                this.alertService.success(this.translateService.instant('LOGINCTRL_RECEIVE_CONFIRMATION_EMAIL_AGAIN'));
                this.spinnerService.stopSpinner();
                this.stateService.go('login');
            },
            error: () => {
                this.alertService.danger(this.translateService.instant('LOGINCTRL_FORGOTCONFIRMFAILURE'));
                this.spinnerService.stopSpinner();
            }
        });
    }
}
