import { Component, OnInit, TemplateRef } from '@angular/core';
import { InAppNavigationService } from '@obo-main/services/inAppNavigation/inAppNavigation.service';
import { TourService } from '@obo-main/services/tour/tour.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './inAppNavigation.component.html',
    styleUrls: ['./inAppNavigation.component.scss']
})
export class InAppNavigationComponent implements OnInit {
    public isActive: boolean;
    public nextButtonDisabledFn: () => boolean;
    public onNextClick: () => any;
    public onPreviousClick: (() => any) | undefined;
    public tourActive: boolean = false;
    public saveButtonTemplate: TemplateRef<any> | undefined;
    public additionalButtonTemplate: TemplateRef<any> | undefined;

    constructor(private inAppNavigationService: InAppNavigationService, private tourService: TourService) {}

    public ngOnInit(): void {
        this.inAppNavigationService.isActive.subscribe((isActive) => (this.isActive = isActive));

        this.inAppNavigationService.saveButtonTemplate.subscribe(
            (saveButtonTemplate) => (this.saveButtonTemplate = saveButtonTemplate)
        );

        this.inAppNavigationService.additionalButtonTemplate.subscribe(
            (additionalButtonTemplate) => (this.additionalButtonTemplate = additionalButtonTemplate)
        );

        this.inAppNavigationService.nextButtonDisabledFn.subscribe((nextDisabled) => (this.nextButtonDisabledFn = nextDisabled));

        this.inAppNavigationService.onNextClick.subscribe((nextCallback) => (this.onNextClick = nextCallback));

        this.inAppNavigationService.onPreviousClick.subscribe((previousCallback) => (this.onPreviousClick = previousCallback));

        this.tourService.tourChange.subscribe((t) => {
            this.tourActive = !!t;
        });
    }

    toggleTourRestart() {
        this.tourService.restartTour();
    }
}
