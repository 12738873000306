import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { getRouteLanguage } from '@obo-main/utils/staticHelperFunctions';

@Injectable()
export class LocaleService {
    public loadLocale(localeId: string) {
        const routeLocaleId = getRouteLanguage(localeId);
        Promise.all([
            import(
                /* webpackInclude: /(\/|\\)(cs|en|nl|pt|de|fr|es|ru|fi|hr|hu|lt|lv|pl|sr)\.mjs$/ */
                /* webpackChunkName: "ng-locales" */
                `/node_modules/@angular/common/locales/${routeLocaleId}.mjs`
            ),
            import(
                /* webpackInclude: /(\/|\\)(cs|en|nl|pt|de|fr|es|ru|fi|hr|hu|lt|lv|pl|sr)\.mjs$/ */
                /* webpackChunkName: "ng-locales-extra" */
                `/node_modules/@angular/common/locales/extra/${routeLocaleId}.mjs`
            )
        ]).then((results) => registerLocaleData(results[0].default, localeId, results[1].default));
    }
}
