import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResetPasswordRequest } from '@obo-account/models/account.models';
import { ResetPasswordService } from '@obo-account/resetPassword/resetPassword.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { SEOService } from '@obo-main/services/seo/seo.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'app-confirm-account',
    templateUrl: './resetPassword.component.html'
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {
    @Input()
    public token: string | undefined;
    @Input()
    public userId: string | undefined;
    @Input()
    public passwordPattern: string;
    public data: ResetPasswordRequest = new ResetPasswordRequest();
    constructor(
        private logger: Logger,
        private seo: SEOService,
        private translateService: TranslateService,
        private resetPasswordService: ResetPasswordService,
        private spinnerService: SpinnerService,
        private alertSerice: AlertService,
        private stateService: StateService
    ) {}

    public ngOnInit(): void {
        this.seo.updateTitle(this.translateService.instant('RESET_PASSWORD_META_TITLE'));
    }

    public ngAfterViewInit(): void {
        this.logger.debug(`resetPassword data: token: ${this.token}, userId: ${this.userId}`);
        if (typeof this.userId !== 'string' || typeof this.token !== 'string') {
            this.alertSerice.danger(this.translateService.instant('LOGINCTRL_INVALID_TOKEN'));
            this.stateService.go('login');
        } else {
            this.data.token = this.token;
            this.data.id = this.userId;
        }
    }

    /**
     * takes an observable and redirects the user to login after response is there
     * @param obs
     */
    public resetPassword(): void {
        this.spinnerService.startSpinner();
        this.resetPasswordService.resetAccount(this.data).subscribe({
            next: () => {
                this.alertSerice.success(this.translateService.instant('LOGINCTRL_RESETPASSWORDSUCCESS'));
                this.spinnerService.stopSpinner();
                this.stateService.go('login');
            },
            error: () => {
                this.alertSerice.danger(this.translateService.instant('LOGINCTRL_RESETPASSWORDFAILURE'));
                this.spinnerService.stopSpinner();
                this.stateService.go('login');
            }
        });
    }
}
