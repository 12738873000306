<div class="mb-3 d-none d-md-block" ngbDropdown [display]="'static'" autoClose="outside">
    <div class="dropdown-toggle-container">
        <button
                class="form-control w-auto no-border"
                [ngClass]="{ 'form-control-border': withBorder }"
                type="button"
                [id]="id"
                ngbDropdownToggle
                [disabled]="filter.disabled">
        <span>
            {{ filter.label }}
            @if (showSelectedValue && filter.selectedOption) {
                <span>
                -
                <b>{{ filter.selectedOption.label }}</b>
            </span>
            }
        </span>
            <fa-icon class="ms-2" [icon]="['fal', 'angle-down']" size="2x"></fa-icon>
        </button>
    </div>
    <form class="show-top-border" ngbDropdownMenu [attr.aria-labelledby]="id">
        <div class="items">
            @if (filter.value) {
                <input class="hide-input dropdown-radio" type="radio" [id]="'resetRadio-'+ id"
                       (click)="filter.reset()"/>
                <label class="item" [for]="'resetRadio-'+ id">
                    {{ resetBtnText }}
                </label>
            }
            @for (option of filter.options; track option; let i = $index) {
                <input class="hide-input" [formControl]="filter" [value]="option" [attr.id]="id+'-'+i" type="radio"/>
                <label class="item" [attr.for]="id+'-'+i">
                    @if (filterOptionTpl) {
                        <ng-template
                                [ngTemplateOutlet]="filterOptionTpl.templateRef"
                                [ngTemplateOutletContext]="{
                        $implicit: option
                    }"></ng-template>
                    } @else {
                        {{ option.label }} {{ option.prediction }}
                    }
                </label>
            }
        </div>
    </form>
</div>
<button
        class="form-control w-auto filter-mobile-btn d-block d-md-none" [class.active]="filter.isActive"
        type="button" (click)="toggleFilter()">
                <span class="d-flex align-items-center"><span>{{ filter.label }}</span>
                    @if (filter.getTags().tags.length > 0) {
                        <span
                                class="ms-2 badge">{{ filter.getTags().tags.length }}</span>
                    }
                </span>
</button>
