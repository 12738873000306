import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, NgZone, Optional } from '@angular/core';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class ImageService {
    constructor(
        @Inject('APIPREFIX') private apiPrefix: string,
        private http: HttpClient,
        private logger: Logger,
        @Optional() @Inject(DOCUMENT) private document: Document,
        private ngZone: NgZone
    ) {}

    /**
     * Creates an HTMLImageElement from given Url
     * @param url
     * @param byPassCors
     * @param rejectOnError
     */
    public createImageFromUrl(url: string, byPassCors: boolean = false, rejectOnError = false): Observable<HTMLImageElement> {
        return this.ngZone.runOutsideAngular(() => {
            this.logger.debug(`ImageService: Creating Image from Url: ${url}`);
            return new Observable<HTMLImageElement>((obs) => {
                let img = new Image();
                if (byPassCors) {
                    img.setAttribute('crossOrigin', 'anonymous');
                }

                img.onload = () => {
                    obs.next(img);
                    obs.complete();
                };
                img.onerror = () => {
                    if (!rejectOnError) {
                        this.createImageFromUrl('/assets/img/dummy/obo.png', byPassCors, true).subscribe({
                            next: (res) => {
                                obs.next(res);
                                obs.complete();
                            },
                            error: (err) => {
                                obs.error(err);
                            }
                        });
                    } else {
                        obs.error(`Error Loading Image with url: ${url}.rejectOnError = true`);
                    }
                };
                img.src = url;
            });
        });
    }

    /**
     * Returns the path of an image serves by the api
     * @param id
     * @param maxWidth
     * @param maxHeight
     */
    public getImagePath(id: string, maxWidth: number = 300, maxHeight: number = 300): string {
        return `${this.apiPrefix}Images/${encodeURIComponent(id)}?maxHeight=${maxHeight}&maxWidth=${maxWidth}`;
    }

    /**
     *
     * @param url Returns the Image from API
     */
    public loadApiImage(url: string): Observable<string> {
        return this.ngZone.runOutsideAngular(() => {
            return this.http
                .get(`${this.apiPrefix}${url}`, {
                    headers: new HttpHeaders({
                        accept: 'image',
                        'Cache-Control': 'no-cache',
                        Pragma: 'no-cache',
                        Expires: '-1'
                    }),
                    observe: 'response',
                    responseType: 'arraybuffer'
                })
                .pipe(
                    mergeMap((response: HttpResponse<ArrayBuffer>) => {
                        if (!response.body) {
                            return throwError(() => new Error('No Image available'));
                        }
                        return of(
                            URL.createObjectURL(
                                new Blob([response.body!], {
                                    type: this.getImageType(response.body!)
                                })
                            )
                        );
                    })
                );
        });
    }

    public revokeObjectUrl(url: any): void {
        URL.revokeObjectURL(url);
    }

    /**
     * Resizes an image and rturns the newly created image as base64 string
     * @param img the image to resize
     * @param width width in px
     * @param height height in px
     * @param destinationFormat
     * @param quality
     * @param fillColor color the background shall have when the destinationFormat is jpeg (no transparency possible)
     */
    public resizeImage(
        img: HTMLImageElement | string,
        width: number,
        height: number,
        destinationFormat: 'jpeg' | 'png',
        quality: number = 1,
        fillColor: string = '#ffffff'
    ): Observable<string> {
        return this.ngZone.runOutsideAngular(() => {
            const drawImage = (img: HTMLImageElement) => {
                const canvas = this.document.createElement('canvas') as HTMLCanvasElement;
                canvas.height = height;
                canvas.width = width;
                const canvasContext = canvas.getContext('2d');
                if (!canvasContext) {
                    throw new Error('No CancasContext available');
                } else {
                    const hRatio = canvas.width / img.width;
                    const vRatio = canvas.height / img.height;
                    const ratio = Math.min(hRatio, vRatio);
                    const centerShift_x = (canvas.width - img.width * ratio) / 2;
                    const centerShift_y = (canvas.height - img.height * ratio) / 2;
                    if (destinationFormat === 'jpeg') {
                        canvasContext.fillStyle = fillColor;
                        canvasContext.fillRect(0, 0, canvas.width, canvas.height);
                    }
                    canvasContext.drawImage(
                        img,
                        0,
                        0,
                        img.width,
                        img.height,
                        centerShift_x,
                        centerShift_y,
                        img.width * ratio,
                        img.height * ratio
                    );
                    return canvas.toDataURL(`image/${destinationFormat}`, quality);
                }
            };
            return new Observable<string>((obs) => {
                if (typeof img === 'string') {
                    let imgToDraw = new Image();
                    imgToDraw.onload = () => {
                        obs.next(drawImage(imgToDraw));
                        obs.complete();
                    };
                    imgToDraw.src = img;
                } else {
                    obs.next(drawImage(img));
                    obs.complete();
                }
            });
        });
    }

    private getImageType(arrayBuffer: ArrayBuffer): string {
        var type = '';
        var dv = new DataView(arrayBuffer, 0, 5);
        var nume1 = dv.getUint8(0);
        var nume2 = dv.getUint8(1);
        var hex = nume1.toString(16) + nume2.toString(16);

        switch (hex) {
            case '8950':
                return 'image/png';
            case '4749':
                return 'image/gif';
            case '424d':
                return 'image/bmp';
            case 'ffd8':
                return 'image/jpeg';
            default:
                throw new Error('Unknown ImageType');
        }
    }
}
