import { Component, Input } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
@Component({
    selector: 'app-already-registered',
    templateUrl: './alreadyRegistered.component.html',
    styleUrls: ['./alreadyRegistered.component.scss']
})
export class AlreadyRegisteredComponent {
    @Input()
    public email?: string;
    public title: string;

    constructor(private uiRouterGlobals: UIRouterGlobals) {
        this.title = 'Emailadresse wird bereits verwendet';
    }

    public get formTitle() {
        return this.uiRouterGlobals.current.data && this.uiRouterGlobals.current.data.formTitle;
    }

    public get formSubTitle(): string {
        return this.uiRouterGlobals.current.data && this.uiRouterGlobals.current.data.formSubTitle;
    }

    public advantages = [
        'LANDING_ADVANTAGE_PROJECTS',
        'LANDING_ADVANTAGE_PLANNINGS',
        'LANDING_ADVANTAGE_EXPORTS',
        'LANDING_ADVANTAGE_DOWNLOADS'
    ];

    public registrationProcess = [
        {
            icon: ['fas', 'file-signature'],
            text: 'LANDING_REGISTRATION_PROCESS_FILL_FORM'
        },
        {
            icon: ['fas', 'envelope'],
            text: 'LANDING_REGISTRATION_PROCESS_RECEIVE_EMAIL'
        },
        {
            icon: ['fas', 'external-link'],
            text: 'LANDING_REGISTRATION_PROCESS_CLICK_CONFIRMATION_LINK'
        },
        {
            icon: ['fas', 'user-check'],
            text: 'LANDING_REGISTRATION_PROCESS_CLICK_REGISTRATION_SUCCESSFUL'
        }
    ];
}
