import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SidebarTabContentComponent } from '@obo-libraries/ng-sidebar/sidebar.component/sidebarTabs/sidebarTabContent/sidebarTabContent.component';
import { SidebarTabsComponent } from '@obo-libraries/ng-sidebar/sidebar.component/sidebarTabs/sidebarTabs.component';
import { InAppNavigationService } from '@obo-main/services/inAppNavigation/inAppNavigation.service';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SidebarContainerComponent } from '@obo-libraries/ng-sidebar';
import { isBrowser, isIOS, isLTR } from '../utils';

@Component({
    selector: 'lib-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {
    // `openedChange` allows for "2-way" data binding
    @HostBinding('class.open')
    @Input()
    opened: boolean;
    @Input() title: string;
    @Input() showFAB: boolean = true;
    @Input() isFabSecond: boolean = false;
    @Input() showActiveFilter: boolean;
    @Input() toggleButtonFaIcon: string | undefined;
    @Output() openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() mode: 'over' | 'push' | 'slide' = 'over';
    @Input() dock: boolean = false;
    @Input() dockedSize: string = '0px';
    @Input() position: 'start' | 'end' | 'left' | 'right' | 'top' | 'bottom' = 'start';
    @Input() animate: boolean = true;

    @Input() autoCollapseHeight: number;
    @Input() autoCollapseWidth: number;
    @Input() autoCollapseOnInit: boolean = true;

    @Input() ariaLabel: string;
    @Input() trapFocus: boolean = false;
    @Input() autoFocus: boolean = true;

    @Input() showBackdrop: boolean = false;
    @Input() closeOnClickBackdrop: boolean = false;
    @Input() closeOnClickOutside: boolean = true;

    @Input() keyClose: boolean = false;
    @Input() keyCode: string = '27'; // Default to ESC key

    @Input() withoutTabs: boolean = false;

    @Output() openedStart: EventEmitter<null> = new EventEmitter<null>();
    @Output() sidebarOpened: EventEmitter<null> = new EventEmitter<null>();
    @Output() closedStart: EventEmitter<null> = new EventEmitter<null>();
    @Output() sidebarClosed: EventEmitter<null> = new EventEmitter<null>();
    @Output() transitionEnded: EventEmitter<null> = new EventEmitter<null>();
    @Output() modelChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() positionChanged: EventEmitter<string> = new EventEmitter<string>();

    /** @internal */
    @Output() _onRerender: EventEmitter<null> = new EventEmitter<null>();

    /** @internal */
    @ViewChild('sidebar') _elSidebar: ElementRef;
    @ViewChild('sidebarTabs') _sidebarTabs: SidebarTabsComponent;

    @ContentChild('header') public header: TemplateRef<SidebarComponent>;
    @ContentChild('content') public content: TemplateRef<SidebarComponent>;
    @ContentChild(SidebarTabsComponent) public tabContainer: SidebarTabsComponent;
    @ContentChildren(SidebarTabContentComponent) public tabContents: QueryList<SidebarTabContentComponent>;

    private windowOrientationChangeSubscription: Subscription;

    private _focusableElementsString: string =
        'a[href], area[href], input:not([disabled]), select:not([disabled]),' +
        'textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex], [contenteditable]';
    private _focusableElements: Array<HTMLElement>;
    private _focusedBeforeOpen: HTMLElement | null;

    private _tabIndexAttr: string = '__tabindex__';
    private _tabIndexIndicatorAttr: string = '__ngsidebar-tabindex__';

    private _wasCollapsed: boolean;

    // Delay initial animation (issues #59, #112)
    private _shouldAnimate: boolean;

    private _clickEvent: string = 'click';
    private _onClickOutsideAttached: boolean = false;
    private _onKeyDownAttached: boolean = false;
    private _onResizeAttached: boolean = false;

    private _isBrowser: boolean;
    inAppNavActive: boolean = false;
    private onDestroy = new Subject();

    constructor(
        @Optional() private _container: SidebarContainerComponent,
        private _ref: ChangeDetectorRef,
        private breakpointObserver: BreakpointObserver,
        private inAppNavigationService: InAppNavigationService
    ) {
        if (!this._container) {
            throw new Error(
                '<lib-sidebar> must be inside a <lib-sidebar-container>. ' +
                    'See https://github.com/arkon/ng-sidebar#usage for more info.'
            );
        }

        this._isBrowser = isBrowser();

        // Handle taps in iOS
        if (this._isBrowser && isIOS() && 'ontouchstart' in window) {
            this._clickEvent = 'touchstart';
        }

        this._normalizePosition();

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this._transitionEnded = this._transitionEnded.bind(this);
        this._onFocusTrap = this._onFocusTrap.bind(this);
        this._onClickOutside = this._onClickOutside.bind(this);
        this._onKeyDown = this._onKeyDown.bind(this);
        this._collapse = this._collapse.bind(this);
    }

    ngOnInit() {
        this.windowOrientationChangeSubscription = fromEvent(window, 'orientationchange').subscribe(() => this.open());

        if (!this._isBrowser) {
            return;
        }

        if (this.animate) {
            this._shouldAnimate = true;
            this.animate = false;
        }

        this._container._addSidebar(this);

        if (this.autoCollapseOnInit) {
            this._collapse();
        }

        this.breakpointObserver
            .observe(['(min-width: 1840px)'])
            .pipe(takeUntil(this.onDestroy))
            .subscribe((state: BreakpointState) => {
                state.matches ? this.open() : this.close();
            });

        this.inAppNavigationService.isActive.subscribe((active) => {
            this.inAppNavActive = active;
        });
        this.open();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this._isBrowser) {
            return;
        }

        if (changes['animate'] && this._shouldAnimate) {
            this._shouldAnimate = changes['animate'].currentValue;
        }

        if (changes['closeOnClickOutside']) {
            if (changes['closeOnClickOutside'].currentValue) {
                this._initCloseClickListener();
            } else {
                this._destroyCloseClickListener();
            }
        }
        if (changes['keyClose']) {
            if (changes['keyClose'].currentValue) {
                this._initCloseKeyDownListener();
            } else {
                this._destroyCloseKeyDownListener();
            }
        }

        if (changes['position']) {
            // Handle "start" and "end" aliases
            this._normalizePosition();

            // Emit change in timeout to allow for position change to be rendered first
            setTimeout(() => {
                this.positionChanged.emit(changes['position'].currentValue);
            });
        }

        if (changes['mode']) {
            setTimeout(() => {
                this.modelChanged.emit(changes['mode'].currentValue);
            });
        }

        if (changes['dock']) {
            this.triggerRerender();
        }

        if (changes['autoCollapseHeight'] || changes['autoCollapseWidth']) {
            this._initCollapseListeners();
        }

        if (changes['opened']) {
            if (this._shouldAnimate) {
                this.animate = true;
                this._shouldAnimate = false;
            }

            if (changes['opened'].currentValue) {
                this.open();
            } else {
                this.close();
            }
        }
    }

    ngOnDestroy(): void {
        this.windowOrientationChangeSubscription.unsubscribe();

        if (!this._isBrowser) {
            return;
        }

        this._destroyCloseListeners();
        this._destroyCollapseListeners();

        this._container._removeSidebar(this);
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    // Sidebar toggling
    // ==============================================================================================

    toggleSidebar(): void {
        if (this.opened) {
            this.close();
        } else {
            this.open();
        }
    }

    /**
     * Opens the sidebar and emits the appropriate events.
     */
    open(): void {
        if (!this._isBrowser) {
            return;
        }

        this.opened = true;
        this.openedChange.emit(true);

        this.openedStart.emit();

        this._ref.detectChanges();

        setTimeout(() => {
            if (this.animate && !this._isModeSlide) {
                this._elSidebar.nativeElement.addEventListener('transitionend', this._transitionEnded);
            } else {
                this._setFocused();
                this._initCloseListeners();

                if (this.opened) {
                    this.sidebarOpened.emit();
                }
            }
        });
    }

    /**
     * Closes the sidebar and emits the appropriate events.
     */
    close(): void {
        if (!this._isBrowser) {
            return;
        }

        this.opened = false;
        this.openedChange.emit(false);

        this.closedStart.emit();

        this._ref.detectChanges();

        setTimeout(() => {
            if (this.animate && !this._isModeSlide) {
                this._elSidebar.nativeElement.addEventListener('transitionend', this._transitionEnded);
            } else {
                this._destroyCloseListeners();

                if (!this.opened) {
                    this.sidebarClosed.emit();
                }
            }
        });
    }

    /**
     * Manually trigger a re-render of the container. Useful if the sidebar contents might change.
     */
    triggerRerender(): void {
        if (!this._isBrowser) {
            return;
        }

        setTimeout(() => {
            this._onRerender.emit();
        });
    }

    /**
     * @internal
     *
     * Computes the transform styles for the sidebar template.
     *
     * @return {CSSStyleDeclaration} The transform styles, with the WebKit-prefixed version as well.
     */
    _getStyle(): CSSStyleDeclaration {
        let transformStyle: string = '';

        // Hides sidebar off screen when closed
        if (!this.opened) {
            const transformDir: string = 'translate' + (this._isLeftOrRight ? 'X' : 'Y');
            let translateAmt: string = `${this._isLeftOrTop ? '-' : ''}100%`;

            transformStyle = `${transformDir}(${translateAmt})`;

            // Docked mode: partially remains open
            // Note that using `calc(...)` within `transform(...)` doesn't work in IE
            if (this.dock && this._dockedSize > 0 && !(this._isModeSlide && this.opened)) {
                transformStyle += ` ${transformDir}(${this._isLeftOrTop ? '+' : '-'}${this.dockedSize})`;
            }
        }

        return {
            webkitTransform: transformStyle,
            transform: transformStyle
        } as CSSStyleDeclaration;
    }

    /**
     * @internal
     *
     * Handles the `transitionend` event on the sidebar to emit the opened/sidebarClosed events after the transform
     * transition is completed.
     */
    _transitionEnded(e: TransitionEvent): void {
        if (e.target === this._elSidebar.nativeElement && e.propertyName.endsWith('transform')) {
            this._setFocused();

            if (this.opened) {
                this._initCloseListeners();
                this.sidebarOpened.emit();
            } else {
                this._destroyCloseListeners();
                this.sidebarClosed.emit();
            }

            this.transitionEnded.emit();

            this._elSidebar.nativeElement.removeEventListener('transitionend', this._transitionEnded);
        }
    }

    // Focus on open/close
    // ==============================================================================================

    /**
     * Returns whether focus should be trapped within the sidebar.
     *
     * @return {boolean} Trap focus inside sidebar.
     */
    private get _shouldTrapFocus(): boolean {
        return this.opened && this.trapFocus && this._isModeOver;
    }

    /**
     * Sets focus to the first focusable element inside the sidebar.
     */
    private _focusFirstItem(): void {
        if (this._focusableElements && this._focusableElements.length > 0) {
            this._focusableElements[0].focus();
        }
    }

    /**
     * Loops focus back to the start of the sidebar if set to do so.
     */
    private _onFocusTrap(e: FocusEvent): void {
        if (this._shouldTrapFocus && !this._elSidebar.nativeElement.contains(e.target)) {
            this._focusFirstItem();
        }
    }

    /**
     * Handles the ability to focus sidebar elements when it's open/closed to ensure that the sidebar is inert when
     * appropriate.
     */
    private _setFocused(): void {
        this._focusableElements = Array.from(
            this._elSidebar.nativeElement.querySelectorAll(this._focusableElementsString)
        ) as Array<HTMLElement>;

        if (this.opened) {
            this._focusedBeforeOpen = document.activeElement as HTMLElement;

            // Restore focusability, with previous tabindex attributes
            for (const el of this._focusableElements) {
                const prevTabIndex = el.getAttribute(this._tabIndexAttr);
                const wasTabIndexSet = el.getAttribute(this._tabIndexIndicatorAttr) !== null;
                if (prevTabIndex !== null) {
                    el.setAttribute('tabindex', prevTabIndex);
                    el.removeAttribute(this._tabIndexAttr);
                } else if (wasTabIndexSet) {
                    el.removeAttribute('tabindex');
                    el.removeAttribute(this._tabIndexIndicatorAttr);
                }
            }

            if (this.autoFocus) {
                this._focusFirstItem();
            }

            document.addEventListener('focus', this._onFocusTrap, true);
        } else {
            // Manually make all focusable elements unfocusable, saving existing tabindex attributes
            for (const el of this._focusableElements) {
                const existingTabIndex = el.getAttribute('tabindex');
                el.setAttribute('tabindex', '-1');
                el.setAttribute(this._tabIndexIndicatorAttr, '');

                if (existingTabIndex !== null) {
                    el.setAttribute(this._tabIndexAttr, existingTabIndex);
                }
            }

            document.removeEventListener('focus', this._onFocusTrap, true);

            // Set focus back to element before the sidebar was opened
            if (this._focusedBeforeOpen && this.autoFocus && this._isModeOver) {
                this._focusedBeforeOpen.focus();
                this._focusedBeforeOpen = null;
            }
        }
    }

    // Close event handlers
    // ==============================================================================================

    /**
     * Initializes event handlers for the closeOnClickOutside and keyClose options.
     */
    private _initCloseListeners(): void {
        this._initCloseClickListener();
        this._initCloseKeyDownListener();
    }

    private _initCloseClickListener(): void {
        // In a timeout so that things render first
        setTimeout(() => {
            if (this.opened && this.closeOnClickOutside && !this._onClickOutsideAttached) {
                document.addEventListener(this._clickEvent, this._onClickOutside as EventListener);
                this._onClickOutsideAttached = true;
            }
        });
    }

    private _initCloseKeyDownListener(): void {
        // In a timeout so that things render first
        setTimeout(() => {
            if (this.opened && this.keyClose && !this._onKeyDownAttached) {
                document.addEventListener('keydown', this._onKeyDown);
                this._onKeyDownAttached = true;
            }
        });
    }

    /**
     * Destroys all event handlers from _initCloseListeners.
     */
    private _destroyCloseListeners(): void {
        this._destroyCloseClickListener();
        this._destroyCloseKeyDownListener();
    }

    private _destroyCloseClickListener(): void {
        if (this._onClickOutsideAttached) {
            document.removeEventListener(this._clickEvent, this._onClickOutside as EventListener);
            this._onClickOutsideAttached = false;
        }
    }

    private _destroyCloseKeyDownListener(): void {
        if (this._onKeyDownAttached) {
            document.removeEventListener('keydown', this._onKeyDown);
            this._onKeyDownAttached = false;
        }
    }

    /**
     * Handles `click` events on anything while the sidebar is open for the closeOnClickOutside option.
     * Programatically closes the sidebar if a click occurs outside the sidebar.
     *
     * @param e {MouseEvent} Mouse click event.
     */
    private _onClickOutside(e: MouseEvent): void {
        if (this._onClickOutsideAttached && this._elSidebar && !this._elSidebar.nativeElement.contains(e.target)) {
            this.close();
        }
    }

    /**
     * Handles the `keydown` event for the keyClose option.
     *
     * @param e {KeyboardEvent} Normalized keydown event.
     */
    private _onKeyDown(e: KeyboardEvent | Event): void {
        if ((e as KeyboardEvent).key === this.keyCode) {
            this.close();
        }
    }

    // Auto collapse handlers
    // ==============================================================================================

    private _initCollapseListeners(): void {
        if (this.autoCollapseHeight || this.autoCollapseWidth) {
            // In a timeout so that things render first
            setTimeout(() => {
                if (!this._onResizeAttached) {
                    window.addEventListener('resize', this._collapse);
                    this._onResizeAttached = true;
                }
            });
        }
    }

    private _destroyCollapseListeners(): void {
        if (this._onResizeAttached) {
            window.removeEventListener('resize', this._collapse);
            this._onResizeAttached = false;
        }
    }

    private _collapse(): void {
        const winHeight: number = window.innerHeight;
        const winWidth: number = window.innerWidth;

        if (this.autoCollapseHeight) {
            if (winHeight <= this.autoCollapseHeight && this.opened) {
                this._wasCollapsed = true;
                this.close();
            } else if (winHeight > this.autoCollapseHeight && this._wasCollapsed) {
                this.open();
                this._wasCollapsed = false;
            }
        }

        if (this.autoCollapseWidth) {
            if (winWidth <= this.autoCollapseWidth && this.opened) {
                this._wasCollapsed = true;
                this.close();
            } else if (winWidth > this.autoCollapseWidth && this._wasCollapsed) {
                this.open();
                this._wasCollapsed = false;
            }
        }
    }

    // Helpers
    // ==============================================================================================

    /**
     * @internal
     *
     * Returns the rendered height of the sidebar (or the docked size).
     * This is used in the sidebar container.
     *
     * @return {number} Height of sidebar.
     */
    get _height(): number {
        if (this._elSidebar.nativeElement) {
            return this._isDocked ? this._dockedSize : this._elSidebar.nativeElement.offsetHeight;
        }

        return 0;
    }

    /**
     * @internal
     *
     * Returns the rendered width of the sidebar (or the docked size).
     * This is used in the sidebar container.
     *
     * @return {number} Width of sidebar.
     */
    get _width(): number {
        if (this._elSidebar.nativeElement) {
            return this._isDocked ? this._dockedSize : this._elSidebar.nativeElement.offsetWidth;
        }

        return 0;
    }

    /**
     * @internal
     *
     * Returns the docked size as a number.
     *
     * @return {number} Docked size.
     */
    get _dockedSize(): number {
        return parseFloat(this.dockedSize);
    }

    /**
     * @internal
     *
     * Returns whether the sidebar is over mode.
     *
     * @return {boolean} Sidebar's mode is "over".
     */
    get _isModeOver(): boolean {
        return this.mode === 'over';
    }

    /**
     * @internal
     *
     * Returns whether the sidebar is push mode.
     *
     * @return {boolean} Sidebar's mode is "push".
     */
    get _isModePush(): boolean {
        return this.mode === 'push';
    }

    /**
     * @internal
     *
     * Returns whether the sidebar is slide mode.
     *
     * @return {boolean} Sidebar's mode is "slide".
     */
    get _isModeSlide(): boolean {
        return this.mode === 'slide';
    }

    /**
     * @internal
     *
     * Returns whether the sidebar is "docked" -- i.e. it is closed but in dock mode.
     *
     * @return {boolean} Sidebar is docked.
     */
    get _isDocked(): boolean {
        return this.dock && !!this.dockedSize && !this.opened;
    }

    /**
     * @internal
     *
     * Returns whether the sidebar is positioned at the left or top.
     *
     * @return {boolean} Sidebar is positioned at the left or top.
     */
    get _isLeftOrTop(): boolean {
        return this.position === 'left' || this.position === 'top';
    }

    /**
     * @internal
     *
     * Returns whether the sidebar is positioned at the left or right.
     *
     * @return {boolean} Sidebar is positioned at the left or right.
     */
    get _isLeftOrRight(): boolean {
        return this.position === 'left' || this.position === 'right';
    }

    /**
     * @internal
     *
     * Returns whether the sidebar is inert -- i.e. the contents cannot be focused.
     *
     * @return {boolean} Sidebar is inert.
     */
    get _isInert(): boolean {
        return !this.opened && !this.dock;
    }

    /**
     * "Normalizes" position. For example, "start" would be "left" if the page is LTR.
     */
    private _normalizePosition(): void {
        const ltr: boolean = isLTR();

        if (this.position === 'start') {
            this.position = ltr ? 'left' : 'right';
        } else if (this.position === 'end') {
            this.position = ltr ? 'right' : 'left';
        }
    }

    /*
    initialize tab functionality if its activated by parameter
     */
    ngAfterContentInit(): void {
        if (this.withoutTabs) {
            this.openedChange.subscribe((opened) => {
                this.tabContainer.sidebarOpened = opened;
            });
            this.tabContainer.toggleClicked.subscribe(() => {
                this.toggleSidebar();
            });
            this.tabContainer.tabClicked.subscribe((index) => {
                if (!this.opened) {
                    this.toggleSidebar();
                }
                let tabContent = this.getTabContentByIndex(index);
                if (tabContent) {
                    tabContent.isActive = true;
                    this.hideOtherTabContents(index);
                }
            });
        } else {
            this.openedChange.subscribe((opened) => {
                this._sidebarTabs.sidebarOpened = opened;
            });
        }
    }

    /*
    hide other tab contents
     */
    private hideOtherTabContents(index: number) {
        this.tabContents.forEach((x) => {
            if (x.tabIndex != index) {
                x.isActive = false;
            }
        });
    }

    /*
    return tab content by its index parameter
     */
    private getTabContentByIndex(index: number): SidebarTabContentComponent | undefined {
        return this.tabContents.find((x) => x.tabIndex == index);
    }
}
